$prefix: "rx";

/* colors */
$primary-color: #0EA5E9;
$secondary-color: #FF7A00;
$success-color: #28a745;
$info-color: #17a2b8;
$warning-color: #ffc107;
$error-color: #dc3545;
$dark-color: #343a40;
$light-color: #f8f9fa;

/* Breakpoints */
$xs-screen: 440px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1140px;
$xxl-screen: 1440px;

/* Typography */
$body-font-family: 'SF Pro Display', sans-serif;
$heading-font-family: "Urbanist", sans-serif;