@import "../../styles/_media-queries.scss";

.about-us {
    padding: 30px 0;

    .about-us-content {
        position: relative;

        .about-us-light-1 {
            position: absolute;
            top: -85px;
            width: 268px;
            height: 275px;
            border-radius: 275px;
            opacity: 0.1;
            background: #FFF;
            filter: blur(50px);

            @include only-phone-lg {
                top: 0;
            }
        }

        .about-us-title {
            text-align: center;
            font-family: Urbanist;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 122.5%;
            letter-spacing: -0.8px;
            margin-bottom: 35px;

            @include only-phone-lg {
                font-size: 24px;
                letter-spacing: -0.48px;
            }
        }

        .about-us-cards {
            --bs-gutter-y: 32px;

            @include only-phone {
                max-width: 550px;
                margin-left: auto;
                margin-right: auto;
            }

            .about-us-card-col {
                flex: 0 0 auto;
                width: 50%;

                @include only-phone-lg {
                    width: 100%;
                }
            }

            .about-us-dec-1 {
                position: absolute;
                width: 246px;
                top: -140px;
                left: -10px;

                @include only-phone-lg {
                    width: 190px;
                    top: -95px;
                    left: -40px;
                }
            }

            .about-us-dec-2 {
                position: absolute;
                width: 246px;
                bottom: -130px;
                right: -10px;

                @include only-phone-lg {
                    width: 190px;
                    bottom: -95px;
                    right: -15px;
                }
            }

            .about-us-card {
                padding: 46px;
                height: 100%;
                display: flex;
                flex-direction: column;

                .about-us-card-text {
                    flex: 1;
                }

                @include only-phone-lg {
                    padding: 24px;
                }

                &.card-1 {
                    border-radius: 41px;
                    border: 4px solid rgba(255, 255, 255, 0.10);
                    background: #000000;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: calc(100% + 15px);
                        height: calc(100% + 15px);
                        background: linear-gradient(189deg, #111 89.34%, #848484 146.42%);
                        border-radius: 41px;
                        z-index: -1;
                    }
                }

                &.card-2 {
                    border-radius: 41px;
                    border: 4px solid rgba(255, 255, 255, 0.40);
                    background: var(--Gold-Gradient, linear-gradient(100deg, #AF8F3E -16.19%, #F0DE71 121.14%));
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: calc(100% + 15px);
                        height: calc(100% + 15px);
                        background: linear-gradient(189deg, #463F2D 89.34%, #796E52 146.42%);
                        border-radius: 41px;
                        z-index: -1;
                    }

                    .about-us-card-title {
                        color: #000000;
                    }

                    .about-us-card-text {
                        color: #00000080;
                    }
                }

                .about-us-card-img {
                    margin-bottom: 24px;
                    display: flex;
                    justify-content: flex-end;

                    img {
                        width: 70px;
                        height: 70px;

                        @include only-phone-lg {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }

                .about-us-card-title {
                    font-family: Urbanist;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: -0.52px;
                    margin-bottom: 11px;

                    @include only-phone-lg {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        letter-spacing: -0.4px;
                    }
                }

                .about-us-card-text {
                    color: #ffffff80;
                    font-family: "SF Pro Display";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 147%;
                    margin-bottom: 25px;

                    @include only-phone-lg {
                        font-size: 14px;
                    }
                }

                .about-us-btns {
                    .btn {
                        padding: 18px 40px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 122.5%;

                        @include only-phone-lg {
                            padding: 14px 30px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}