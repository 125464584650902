@import "../../styles/_media-queries.scss";

.hero {
    padding: 30px 0;

    @include only-phone-md {
        padding: 12px 0;
        margin-bottom: 40px;
    }

    .hero-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .hero-text {
            max-width: 550px;

            @include only-phone-xl {
                max-width: 550px;
                margin: 0 auto;
                width: 100%;
                flex: 0 0 auto;
                order: 2;
            }

            .hero-title {
                font-family: $heading-font-family;
                font-size: 60px;
                font-style: normal;
                font-weight: 600;
                line-height: 122.5%;
                margin-bottom: 16px;

                @include only-phone-xl {
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 122.5%;
                    letter-spacing: -0.6px;
                    margin-bottom: 14px;
                }

                .hero-title-logo {
                    display: inline-block;
                    height: 85px;
                    vertical-align: bottom;

                    @include only-phone-xl {
                        height: 42px;
                    }
                }
            }

            .hero-desc {
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 122.5%;
                color: #ffffff80;
                margin-bottom: 32px;

                @include only-phone-xl {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }

            .hero-btns{
                .btn{
                    .btn-icon{
                        margin-left: 10px;
                    }
                    padding: 18px 40px;
                    
                    @include only-phone-md{
                        font-size: 12px;
                        padding: 14px 30px;
                    }
                }
            }
        }

        .hero-image-wrapper {
            flex: 1;

            @include only-phone-xl {
                order: 1;
                width: 100%;
                flex: 0 0 auto;
                height: max-content;
                margin-bottom: 16px;
            }

            .hero-image {
                width: 100%;

                @include only-phone-xl {
                    position: relative;
                    width: 100%;
                    max-width: 500px;
                    margin: 0 auto;
                }

                .hero-image-parts {
                    position: relative;
                    width: 100%;
                    padding-top: 100%; // based on the aspect ratio of the image

                    .light-1 {
                        background: rgba(255, 255, 255, 0.23);
                        border-radius: 50%;
                        top: 11%;
                        height: 43%;
                        mix-blend-mode: screen;
                        width: 31%;
                        position: absolute;
                        left: 16%;
                        filter: blur(50px);
                    }

                    .light-2 {
                        background: rgba(255, 255, 255, 0.37);
                        border-radius: 50%;
                        top: 30%;
                        height: 22%;
                        mix-blend-mode: screen;
                        width: 21%;
                        position: absolute;
                        right: 16%;
                        filter: blur(50px);
                    }

                    .light-1, .light-2 {
                        /* fix blur flickering in iphone and some devices */
                        transform: translate3d(0, 0, 0); /* or translateZ(0) */
                        will-change: transform; /* Optional: Hints the browser about future changes */
                    }

                    .hero-image-part {
                        position: absolute;
                        display: none;

                        &.hero-orion {
                            display: block;
                            top: 0;
                            left: 0;
                            width: 15.6%;
                            top: 48.2%;
                            left: 12.7%;
                        }

                        &.hero-column-left {
                            display: block;
                            top: 0;
                            left: 0;
                            width: 20.3%;
                            top: 68.5%;
                            left: 11.4%;
                        }

                        &.hero-column-middle {
                            display: block;
                            top: 0;
                            left: 0;
                            width: 28.1%;
                            top: 55.9%;
                            left: 35.9%;
                        }

                        &.hero-column-right {
                            display: block;
                            width: 20.3%;
                            top: 68.5%;
                            left: 68.2%;
                        }

                        &.hero-etherium-blur {
                            display: block;
                            width: 6.4%;
                            top: 35%;
                            left: 18.5%;
                        }

                        &.hero-etherium {
                            display: block;
                            width: 15.4%;
                            top: 38.4%;
                            right: 12.7%;
                        }

                        &.hero-orion-blur {
                            display: block;
                            width: 9%;
                            top: 10.3%;
                            right: 17.3%;
                        }

                        &.hero-orion-3d {
                            display: block;
                            width: 47%;
                            top: 5%;
                            left: 26%;
                        }

                        &.orion-coins-1 {
                            display: block;
                            width: 14.53%;
                            bottom: 3.125%;
                            left: 1.8%;
                        }

                        &.orion-coins-2 {
                            display: block;
                            width: 9.375%;
                            bottom: 13.28%;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}