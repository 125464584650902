@import "../../styles/_media-queries.scss";

.features {
    padding: 30px 0;
    margin-top: 100px;

    @include only-phone-lg {
        margin-top: 40px;
    }

    .features-content {
        .features-title {
            text-align: center;
            font-family: Urbanist;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 122.5%;
            /* 49px */
            letter-spacing: -0.8px;
            margin-bottom: 35px;

            @include only-phone-lg {
                font-size: 24px;
                letter-spacing: -0.48px;
            }
        }

        .feature-cards {
            padding: 0 95px;
            position: relative;

            @include only-phone-lg {
                padding: 0;
                max-width: 550px;
                margin: 0 auto;
            }

            .feature-cards-light {
                width: 518px;
                height: 533px;
                border-radius: 533px;
                opacity: 0.1;
                background: #FFF;
                filter: blur(100px);
                position: absolute;
                top: -190px;
                right: 95px;
            }

            .feature-card-wrapper {
                margin-bottom: 40px;

                @include only-phone-lg {
                    margin-bottom: 26px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .ai-search-engine {
            .feature-card {
                .ai-icon {
                    fill: currentColor;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    vertical-align: baseline;
                    margin-right: 10px;

                    @include only-phone-lg {
                        width: 18px;
                        height: 18px;
                        margin-right: 5px;
                    }
                }

                .feature-card-text {
                    margin-right: 32px;

                    @include only-phone-lg {
                        order: 2;
                        margin-right: 0;
                    }
                }

                .feature-card-img {
                    position: relative;
                    margin-left: auto;

                    @include only-phone-lg {
                        margin-left: 0;
                        order: 1;
                        width: 100%;
                        margin-bottom: 32px;
                        padding: 20px;
                    }

                    .ai-search-img {
                        width: 374px;
                        //height: 301.333px;
                        padding-top: 80.57%;
                        /* Aspect ratio padding */
                        background-image: url('../../assets/images/ai-search-engine.png');
                        background-size: cover;
                        background-position: center;

                        @include only-phone-xl {
                            width: 320px;
                        }

                        @include only-phone-lg {
                            width: 100%;
                        }
                    }

                    .ai-search-img-light {
                        width: 204px;
                        height: 143px;
                        background: #FFF;
                        opacity: 0.3;
                        filter: blur(75px);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .coin-1 {
                        width: 16.04%;
                        position: absolute;
                        top: -8.02%;
                        right: 0;
                        z-index: 2;

                        @include only-phone-lg {
                            width: 14%;
                            top: 0%;
                            right: 3%;
                        }
                    }

                    .coin-2 {
                        width: 29.41%;
                        position: absolute;
                        bottom: 0;
                        left: -16.04%;
                        z-index: 2;

                        @include only-phone-lg {
                            width: 20%;
                            bottom: 10%;
                            left: 0%;
                        }
                    }
                }
            }
        }

        .ad-blocker {
            .feature-card {
                .feature-card-text {
                    margin-left: 32px;

                    @include only-phone-lg {
                        margin-left: 0;
                    }
                }

                .feature-card-img {
                    margin-right: auto;
                    position: relative;
                    z-index: 1;

                    @include only-phone-lg {
                        margin-right: 0;
                        width: 100%;
                        margin-bottom: 32px;
                    }

                    .ad-blocker-img {
                        width: 390px;

                        @include only-phone-xl {
                            width: 320px;
                        }

                        @include only-phone-lg {
                            width: 100%;
                        }
                    }

                    .dec-1 {
                        width: 15.38%;
                        position: absolute;
                        top: 7.69%;
                        right: 4.10%;
                        z-index: 2;
                    }

                    .dec-2 {
                        width: 24.36%;
                        position: absolute;
                        bottom: 2.56%;
                        left: 6.41%;
                        z-index: 2;
                    }

                    .light-1 {
                        width: 52.31%;
                        height: 37.18%;
                        background: #FFF;
                        opacity: 0.3;
                        filter: blur(75px);
                        position: absolute;
                        top: -2.56%;
                        left: 8.97%;
                    }

                    .forbid-icon {
                        width: 32%;
                        position: absolute;
                        top: 21.5%;
                        left: 34%;
                    }
                }
            }
        }

        .vpn {
            .feature-card {
                .feature-card-img {
                    margin-right: 50px;
                    margin-left: auto;
                    z-index: 1;
                    position: relative;

                    @include only-phone-xl {
                        margin-right: 25px;
                    }

                    @include only-phone-lg {
                        margin-right: 0;
                        margin-left: 0;
                        order: 1;
                        width: 55%;
                        margin: 0 auto;
                        margin-bottom: 32px;
                    }

                    img {
                        width: 270px;

                        @include only-phone-xl {
                            width: 200px;
                        }

                        @include only-phone-lg {
                            width: 100%;
                        }
                    }

                    .dec-1 {
                        width: 33.33%;
                        position: absolute;
                        bottom: 25.93%;
                        left: -22.22%;
                    }

                    .dec-2 {
                        width: 55.56%;
                        position: absolute;
                        bottom: 0%;
                        right: -29.63%;
                    }

                    .light-1 {
                        width: 75%;
                        height: 45%;
                        transform: rotate(54.57deg);
                        background: #FFF;
                        opacity: 0.4;
                        filter: blur(75px);
                        position: absolute;
                        bottom: 20%;
                        left: -10%;
                        z-index: -1;
                    }
                }

                .feature-card-text {
                    margin-right: 40px;

                    @include only-phone-lg {
                        margin-right: 0;
                        order: 2;
                    }
                }
            }
        }

        .content-filter {
            .feature-card {
                .feature-card-text {
                    margin-left: 75px;

                    @include only-phone-lg {
                        margin-left: 0;
                    }
                }

                .feature-card-img {
                    margin-right: auto;
                    position: relative;

                    @include only-phone-lg {
                        margin-right: 0;
                        width: 75%;
                        margin: 0 auto;
                        margin-bottom: 32px;
                    }

                    img {
                        width: 334px;

                        @include only-phone-xl {
                            width: 270px;
                        }

                        @include only-phone-lg {
                            width: 100%;
                        }
                    }

                    .dec-1 {
                        width: 53.29%;
                        position: absolute;
                        top: -20%;
                        left: -20%;
                    }

                    .dec-2 {
                        width: 25.45%;
                        position: absolute;
                        top: 0%;
                        right: -16%;
                    }

                    .dec-3 {
                        width: 29.03%;
                        position: absolute;
                        bottom: 20%;
                        left: -12%;
                    }

                    .dec-4 {
                        width: 22.73%;
                        position: absolute;
                        bottom: 20%;
                        right: -16%;
                    }
                }
            }
        }
    }
}