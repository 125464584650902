.btn {
    position: relative;
    padding: 16px 40px;
    border-radius: 74px;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #070707;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: background-position 0.4s ease, box-shadow 0.4s ease, transform 0.2s ease;

    background: linear-gradient(120deg,
            #AF8F3E -16.19%,
            #F0DE71 50%,
            #AF8F3E 121.14%) padding-box,
        linear-gradient(165deg, #fff4d6 25%, #cdb587 40%) border-box;
    background-size: 200% 100%;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset,
        -8px -5px 5px 0px rgba(0, 0, 0, 0.25) inset,
        -9px 14px 84.5px 0px rgba(207, 180, 122, 0.2);

    &:hover {
        background-position: 100% 0;
        transform: scale(1.02);
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0.25) inset, -9px 14px 84.5px 0px rgba(207, 180, 122, 0.20), 0px 0px 10px #caae71;
    }

    &.secondary {
        background-image: url('../../assets/images/marble-texture.png') !important;
        background-size: cover;
        background-position: center;
        background-blend-mode: multiply, normal;
        box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset,
            -8px -5px 5px 0px rgba(0, 0, 0, 0.25) inset,
            -9px 14px 84.5px 0px rgba(207, 180, 122, 0.2);

        &:hover {
            transform: scale(1.02);
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0.25) inset, -9px 14px 84.5px 0px rgba(207, 180, 122, 0.20), 0px 0px 10px #ffffff;
        }
    }
}