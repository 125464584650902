@import "../../styles/_media-queries.scss";

.feature-card {
    padding: 60px 48px;
    border-radius: 41px;
    border: 4px solid rgba(255, 255, 255, 0.10);
    background: #000000;
    position: relative;
    display: flex;
    align-items: center;

    @include only-phone-lg {
        flex-wrap: wrap;
        padding: 40px 28px;
        border-radius: 25px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% + 15px);
        height: calc(100% + 15px);
        background: linear-gradient(189deg, #111 89.34%, #848484 146.42%);
        border-radius: 41px;
        z-index: -1;

        @include only-phone-md {
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            border-radius: 25px;
        }
    }

    &.reverse {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% + 15px);
            height: calc(100% + 15px);
            background: linear-gradient(-189deg, #111 89.34%, #848484 146.42%);
            border-radius: 41px;
            z-index: -1;

            @include only-phone-md {
                width: calc(100% + 10px);
                height: calc(100% + 10px);
                border-radius: 25px;
            }
        }
    }

    .feature-card-text {
        max-width: 420px;

        .feature-card-title {
            color: #FFF;
            font-family: Urbanist;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;
            line-height: 122.5%;
            /* 41.65px */
            letter-spacing: -0.68px;
            margin-bottom: 12px;

            @include only-phone-lg {
                font-size: 20px;
                letter-spacing: -0.4px;
            }
        }

        .feature-card-desc {
            color: #FFFFFF80;
            font-family: "SF Pro Display";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 147%;
            /* 26.46px */
            margin-bottom: 20px;

            @include only-phone-lg {
                font-size: 14px;
            }
        }

        .feature-card-btns {
            .btn {
                padding: 18px 40px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 122.5%;

                @include only-phone-lg {
                    padding: 14px 30px;
                    font-size: 12px;
                }
            }
        }
    }
}