@import "../../styles/_media-queries.scss";

.ip-info {
    .ip-info-content {
        @include only-phone-md {
            padding: 0 20px;
        }

        .ip-info-details-wrapper {
            padding: 22px;
            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;
            background: #131313B3;
            display: flex;
            justify-content: center;
            align-items: center;

            .ip-info-details {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .left-col,
                .right-col {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    @include only-phone-md {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }

                .left-col {
                    margin-right: 115px;

                    @include only-phone-lg {
                        margin-right: 32px;
                    }

                    @include only-phone-md {
                        margin-right: 60px;
                    }
                }

                .ip-info-details-item {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 122.5%; /* 14.7px */
                    margin-right: 115px;

                    &:last-child {
                        margin-right: 0;
                    }

                    @include only-phone-lg {
                        font-size: 14px;
                        margin-right: 32px;
                    }

                    @include only-phone-md {
                        font-size: 12px;
                        margin-right: 0;
                        margin-bottom: 4px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .ip-info-title {
                        color: #ffffff80;
                    }
                }
            }
        }
    }
}