@import "../../styles/_media-queries.scss";

.footer {
    padding: 34px 0;

    @include only-phone-md {
        padding: 0;
    }

    .footer-content-wrap {
        padding-top: 75px;
        padding-bottom: 75px;
        position: relative;

        @include only-phone-xl {
            padding-bottom: 300px;
        }

        @include only-phone-md {
            padding-bottom: 215px;
        }

        .light-1 {
            width: 217px;
            height: 223px;
            background: #FFF;
            opacity: 0.7;
            filter: blur(100px);
            position: absolute;
            left: -150px;
            bottom: -220px;
        }

        .light-2 {
            width: 446px;
            height: 459px;
            border-radius: 459px;
            opacity: 0.1;
            background: #FFF;
            filter: blur(100px);
            position: absolute;
            left: -120px;
            bottom: -170px;

            @include only-phone-md {
                display: none;
            }
        }

        .light-3 {
            width: 217px;
            height: 223px;
            background: #FFF;
            opacity: 0.7;
            filter: blur(100px);
            position: absolute;
            right: -150px;
            bottom: -220px;
        }

        .light-4 {
            width: 446px;
            height: 459px;
            border-radius: 459px;
            opacity: 0.1;
            background: #FFF;
            filter: blur(100px);
            position: absolute;
            right: -120px;
            bottom: -170px;

            @include only-phone-md {
                display: none;
            }
        }

        .footer-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .footer-logo {
                margin-bottom: 20px;

                img {
                    width: 206px;

                    @include only-phone-md {
                        width: 106px;
                    }
                }
            }

            .footer-desc {
                max-width: 450px;
                color: #FFFFFF80;
                text-align: center;
                font-family: "SF Pro Display";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 122.5%;
                /* 24.5px */
                margin-bottom: 50px;

                @include only-phone-md {
                    max-width: 262px;
                    font-size: 14px;
                    margin-bottom: 34px;
                }
            }

            .footer-links-wrapper {
                max-width: 450px;
                margin: 0 auto;
                margin-bottom: 40px;


                @include only-phone-md {
                    margin-bottom: 30px;
                }

                .footer-links {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin: -10px;

                    li {
                        padding: 5px;
                        position: relative;

                        &:last-child {
                            margin-right: 0;
                        }

                        .footer-link {
                            position: relative;

                            // gradeint border
                            &::before {
                                content: "";
                                background: linear-gradient(100deg, #AF8F3E -16.19%, #F0DE71 121.14%);
                                position: absolute;
                                top: -2px;
                                left: -2px;
                                right: -2px;
                                bottom: -2px;
                                border-radius: 76px;
                                z-index: -1;
                                transition: all 0.3s ease;
                                opacity: 0.5;
                            }

                            a {
                                border-radius: 76px;
                                padding: 10px 18px;
                                background: #0F0F0F;
                                color: #FFFFFF;
                                font-family: Urbanist;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 122.5%;
                                /* 19.6px */
                                letter-spacing: -0.32px;
                                transition: all 0.3s ease;
                                position: relative;
                                display: flex;
                                align-items: center;

                                @include only-phone-md {
                                    padding: 8px 16px;
                                    font-size: 12px;
                                }

                                &::before {
                                    content: "";
                                    width: 100%;
                                    height: 100%;
                                    background: linear-gradient(100deg, #AF8F3E -16.19%, #F0DE71 121.14%);
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    border-radius: 76px;
                                    z-index: -1;
                                    transition: all 0.3s ease;
                                    opacity: 0;
                                }

                                &:hover {
                                    background: #0F0F0F00;
                                    color: #000;

                                    &::before {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .footer-copyright {
                color: #FFFFFF4D;
                text-align: center;
                font-family: "SF Pro Display";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 147%;
                /* 20.58px */

                @include only-phone-md {
                    font-size: 10px;
                }
            }
        }

        .footer-left-col-1 {
            position: absolute;
            bottom: 0;
            left: 190px;
            display: flex;
            flex-direction: column;
            width: 115px;

            @include only-phone-xl {
                width: 90px;
            }

            @include only-phone-md {
                width: 65px;
                left: 110px;
            }

            .coin-img {
                width: 73.91%;
                margin: 0 auto;
                margin-bottom: 28.7%;
            }

            .col-img {
                width: 100%;
            }
        }

        .footer-left-col-2 {
            position: absolute;
            bottom: 0;
            left: 30px;
            display: flex;
            flex-direction: column;
            width: 115px;

            @include only-phone-xl {
                width: 90px;
            }

            @include only-phone-md {
                width: 65px;
                left: 15px;
            }

            .coin-img {
                width: 73.91%;
                margin: 0 auto;
                margin-bottom: 28.7%;
            }

            .col-img {
                width: 100%;
            }
        }

        .footer-right-col-1 {
            position: absolute;
            bottom: 0;
            right: 190px;
            display: flex;
            flex-direction: column;
            width: 115px;

            @include only-phone-xl {
                width: 90px;
            }

            @include only-phone-md {
                width: 65px;
                right: 110px;
            }

            .coin-img {
                width: 73.91%;
                margin: 0 auto;
                margin-bottom: 28.7%;
            }

            .col-img {
                width: 100%;
            }
        }

        .footer-right-col-2 {
            position: absolute;
            bottom: 0;
            right: 30px;
            display: flex;
            flex-direction: column;
            width: 115px;

            @include only-phone-xl {
                width: 90px;
            }

            @include only-phone-md {
                width: 65px;
                right: 15px;
            }

            .coin-img {
                width: 73.91%;
                margin: 0 auto;
                margin-bottom: 28.7%;
            }

            .col-img {
                width: 100%;
            }
        }
    }
}