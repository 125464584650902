.btn-outline {
    position: relative;
    padding: 16px 40px;
    outline: 1.5px solid rgba(255, 255, 255, 0.20);
    border-radius: 74px;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    background: none;
    color: #ffffff;
    transition: all 0.5s ease;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(100deg, #AF8F3E -16.19%, #F0DE71 121.14%);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: -1;
    }

    &:hover {
        color: #070707;
        box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset, -8px -5px 5px 0px rgba(0, 0, 0, 0.25) inset, -9px 14px 84.5px 0px rgba(207, 180, 122, 0.20);

        &:before {
            opacity: 1;
        }
    }
}