@import 'shake_tools';

.shake-slow-1       { @include do-shake('shake-slow-1', 10, 10, 5, 120s, 0.02); }
.shake-slow-2       { @include do-shake('shake-slow-2', 30, 30, 14, 120s, 0.018); }
.shake-slow-3       { @include do-shake('shake-slow-3', 30, 30, 14, 120s, 0.022); }
.shake-slow-4       { @include do-shake('shake-slow-4', 30, 30, 14, 120s, 0.019); }
.shake-slow-5       { @include do-shake('shake-slow-5', 30, 30, 14, 120s, 0.021); }
.shake-slow-6       { @include do-shake('shake-slow-6', 30, 30, 14, 120s, 0.017); }
.shake-slow-7       { @include do-shake('shake-slow-7', 30, 30, 14, 120s, 0.023); }

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes vibrate {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-2px, 2px);
    }

    40% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(2px, 2px);
    }

    80% {
        transform: translate(2px, -2px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes vibrate-mild {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-1px, 1px);
    }

    40% {
        transform: translate(-1px, -1px);
    }

    60% {
        transform: translate(1px, 1px);
    }

    80% {
        transform: translate(1px, -1px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes vibrate-with-rotation {
    0% {
        transform: translate(0) rotate(0);
    }

    20% {
        transform: translate(-2px, 2px) rotate(2deg);
    }

    40% {
        transform: translate(-2px, -2px) rotate(-2deg);
    }

    60% {
        transform: translate(2px, 2px) rotate(2deg);
    }

    80% {
        transform: translate(2px, -2px) rotate(-2deg);
    }

    100% {
        transform: translate(0) rotate(0);
    }
}

@keyframes vibrate-with-zoom {
    0% {
        transform: translate(0) scale(1);
    }

    20% {
        transform: translate(-2px, 2px) scale(0.91);
    }

    40% {
        transform: translate(-2px, -2px) scale(1.09);
    }

    60% {
        transform: translate(2px, 2px) scale(0.91);
    }

    80% {
        transform: translate(2px, -2px) scale(1.09);
    }

    100% {
        transform: translate(0) scale(1);
    }
}

@keyframes waterFloat {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    25% {
        transform: translateY(-10px) rotate(2deg);
    }

    75% {
        transform: translateY(10px) rotate(-2deg);
    }

    100% {
        transform: translateY(0px) rotate(0deg);
    }
}