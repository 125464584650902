@import "../../styles/_media-queries.scss";

.header {
    padding: 16px 0;

    .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-left {
            display: flex;
            align-items: center;

            .sidebar-toggle {
                display: none;
                transition: all 200ms ease-in-out;
                margin-right: 16px;
                background: none;

                @include only-phone-lg {
                    display: block;
                }

                svg {
                    height: 20px;
                }
            }

            .header-logo {
                width: 64px;

                @include only-phone-lg {
                    width: 45px;
                }
            }
        }

        .header-right {
            display: flex;
            align-items: center;

            .header-nav {
                display: flex;
                align-items: center;
                margin-right: 50px;

                @include only-phone-lg {
                    display: none;
                }

                li {
                    margin-right: 70px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a,
                    button {
                        transition: color 0.3s ease;

                        &:hover {
                            color: #c9ac70;
                        }
                    }

                    button {
                        background: none;
                        border: none;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }

                .dropdown-wrapper {
                    position: relative;

                    &.active {
                        .dropdown-btn {
                            .dropdown-icon svg{
                                transform: rotate(180deg);
                            }
                        }

                        .dropdown-content {
                            display: block;
                        }
                    }

                    .dropdown-btn{
                        color: #fff;
                        .dropdown-icon {
                            svg {
                                transition: transform 0.3s ease;
                            }
                        }
                    }

                    .dropdown-content {
                        position: absolute;
                        display: none;
                        padding-top: 16px;
                        top: 100%;
                        z-index: 1000;
                        width: 200px;

                        .dropdown-items {
                            display: flex;
                            flex-direction: column;
                            border-radius: 25px;
                            border: 2.456px solid rgba(255, 255, 255, 0.1);
                            background: #000;
                            overflow: hidden;

                            li {
                                margin-right: 0;
                                width: 100%;
                            }

                            a {
                                display: block;
                                padding: 20px 40px;
                                position: relative;
                                color: #fff;

                                .header-social-icon{
                                    width: 20px;
                                    margin-right: 10px;
                                }

                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    background: linear-gradient(90deg, #FFF -22.26%, rgba(153, 153, 153, 0.00) 64.19%);
                                    opacity: 0;
                                    transition: opacity 0.3s ease;
                                }

                                &:hover {
                                    &::before {
                                        opacity: 0.2;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .launch-app-btn {
                @include only-phone-lg {
                    padding: 12px 27px;
                    font-size: 11px;
                }
            }
        }
    }

    .sidebar-backdrop {
        background-color: rgba(0, 0, 0, .7);
        height: 100vh;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        transition: opacity .2s ease-in-out, backdrop-filter .2s ease-in-out;
        width: 100%;
        z-index: 999;
        backdrop-filter: blur(0px);

        &.active {
            opacity: 1;
            pointer-events: auto;
            backdrop-filter: blur(6px);
        }
    }

    .sidebar {
        border-radius: 0px 0px 25px 25px;
        border: 2.456px solid rgba(255, 255, 255, 0.10);
        background: #000;
        width: 100%;
        max-width: 370px;
        position: fixed;
        left: 50%;
        top: 0;
        transform: translate(-50%, -100%);
        transition: transform .3s ease-in-out;
        z-index: 1000;
        overflow-y: auto;

        &.active {
            transform: translate(-50%, 0);
        }

        .sidebar-content {
            display: flex;
            flex-direction: column;
            padding: 32px 0px;

            .sidebar-header {
                .sidebar-close {
                    background: none;
                    color: #808080;
                    display: flex;
                    margin-left: 30px;
                    margin-bottom: 16px;

                    .add-icon {
                        transform: rotate(45deg);
                    }
                }
            }

            .sidebar-body {
                .sidebar-nav {
                    display: flex;
                    flex-direction: column;

                    li {
                        margin-bottom: 6px;
                        font-family: "SF Pro Display";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 122.5%;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            display: block;
                            padding: 20px 40px;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                background: linear-gradient(90deg, #FFF -22.26%, rgba(153, 153, 153, 0.00) 64.19%);
                                opacity: 0;
                                transition: opacity 0.3s ease;
                            }

                            &:hover {
                                &::before {
                                    opacity: 0.2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}